import type { EditorJsonContent, FalconContent, TilesContent } from '../contentTypes';
import { tileModelToNemo } from './tileModelToNemo';

export const mapHeadlessContent = (content: FalconContent): TilesContent => {
  const { layout_name: name, layout_size: size, tiles } = content.fields;
  const appContent: TilesContent = {
    layout: {
      name,
      size,
    },
    tiles: tiles.map((tile) => tileModelToNemo(tile)),
  };
  return appContent;
};

export const mapFaceContent = (content: EditorJsonContent): TilesContent => {
  const appContent = { ...content };
  return appContent;
};
