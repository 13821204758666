/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Logger } from '@feature-hub/core';
import { ContentChangeListener, ContentServiceV1 } from '@oneaudi/content-service';
import React, { useState } from 'react';
import { ContentContext } from './ContentContext';
import type { EditorJsonContent, FalconContent, TilesContent } from './contentTypes';
import { mapFaceContent, mapHeadlessContent } from './utils/mappers';

export const ContentContextProvider: React.FC<{
  contentService: ContentServiceV1;
  logger?: Logger;
}> = ({ contentService, logger, children }) => {
  const [content, setContent] = useState(
    contentService.getContent() as FalconContent | EditorJsonContent,
  );
  contentService.onContentChange(setContent as ContentChangeListener);
  let tilesContent: TilesContent;
  if (content.__type === 'aem-headless') {
    logger?.info('aem-headless-content:', content);
    tilesContent = mapHeadlessContent(content);
  } else {
    tilesContent = mapFaceContent(content);
  }

  return (
    <ContentContext.Provider value={{ content: tilesContent }}>{children}</ContentContext.Provider>
  );
};

ContentContextProvider.displayName = 'ContentContextProvider';
